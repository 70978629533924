<template>
  <div class="main" v-on:scroll="handleScroll">
    <!--SECCION 1-->
    <!--Shift + Alt + F-->

    <div class="msjForm" v-if="msjAlert">    
      <h2>¡Listo!</h2>
      <p>Ahora tu idea se esta analizando y se esta crenado una opción Adhok a ti. </p>

      <p>Te hemos enviado un correo electrónico para seguir en contacto.</p>

      <button v-on:click="btnEntendido">Entendido</button>
    </div>

    <div class="seccion1" id="home">
      <div class="izq" id="izq">
        <img src="../assets/logoPng.png" alt="Adhok" v-on:click="scrollHome" />

        <div class="contRedes">
          <p id="lineaRedes">
          <span><a id="fb" href="https://www.facebook.com/adhokMX" target="_blank">Facebook</a></span>
          |
          <span><a id="ins" href="https://www.instagram.com/adhok_mx/" target="_blank">Instagram</a></span>
          </p>
        </div>
      </div>

      <div class="der">
        <div class="contSeccion">
          <p id="linea">
            <a id="inicioOp" v-on:click="scrollHome">Home</a>
            |
            <a id="serviciosOp" v-on:click="scrollServicios">Servicios</a>
            |
            <a id="contactoOp" v-on:click="scrollContacto">Contacto</a>
          </p>
        </div>

        <h2>
          Desarrollos
          <p>Adhok</p>
          a ti.
        </h2>

        <button v-on:click="scrollContacto">¡Me interesa!</button>
      </div>
    </div>

    <!--SECCION 2-->

    <div class="seccion2" id="servicios">
      <div class="derSeccion2">
        <div class="serviciosIzq" id="serviciosIzq">
          <h2>Servicios</h2>
          <p>
            Nos dedicamos a elaborar soluciones que se adecuen a tus
            necesidades.
          </p>
        </div>
        <div class="serviciosDer">
          <div class="item" id="devWeb">
            <div class="imagen">
              <img src="../assets/devB.png" alt="" />
            </div>
            <div class="texto textoIzquierda">
              <h2>Desarrollo web</h2>
              <p>
                Que se ajuste a cualquier dispositivo, que tenga un diseño desde elgante o divertido, tu eliges.
              </p>
            </div>
          </div>
          <div class="item" id="uxUi">
            <div class="texto textoDerecha">
              <h2>Diseño Ux / Ui</h2>
              <p>
                Creamos interfaces amigables con gran experiencia de usuario y diseños espectaculares.
              </p>
            </div>
            <div class="imagen">
              <img src="../assets/diseno.png" alt="" />
            </div>
          </div>
          <div class="item" id="seo">
            <div class="imagen">
              <img src="../assets/seo.png" alt="" />
            </div>
            <div class="texto textoIzquierda">
              <h2>SEO</h2>
              <p>
                Te ayudamos a mejorar el posicionamiento de tu sitio web.
              </p>
            </div>
          </div>
          <div class="item" id="apis">
            <div class="texto textoDerecha">
              <h2>API´s y Base de datos</h2>
              <p>
                Conecta tu propia infrastructura a traves de desarrollo de API´s y Bases de datos. 
              </p>
            </div>
            <div class="imagen">
              <img src="../assets/base.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--SECCION 3-->

    <div class="seccion3" id="contacto">
      <div class="derSeccion3">
        <div class="contactoIzq" id="contactoIzq">
          <p>Mándanos tu idea y pronto estaremos en</p>
          <h2>Contacto</h2>
        </div>
        <div class="contactoDer">
          <form id="formulario" onsubmit="return false">
            <div class="rowForm">
              <p>Nombre</p>
              <input type="text" id="nombre" />
            </div>

            <div class="rowForm">
              <p>Correo</p>
              <input type="email" name="" id="email" />
            </div>

            <div class="rowForm">
              <p>Necesito</p>

              <select name="" id="desarrollo">
                <option value="Opcion">Selecciona una opción</option>
                <option value="DEV-WEB">Desarrollo web</option>
                <option value="UX/UI">Diseño UX / UI</option>
                <option value="OPT-WEB">Optimizar mi web</option>
                <option value="API">Desarrollo de API</option>
                <option value="BD">Bases de datos</option>
                <option value="OTRO">Otro</option>
              </select>
            </div>

            <div class="rowForm derecha">
              <button v-on:click="btnMensaje">Enviar mensaje</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!--FOOTER -->

    <footer class="pie">
      <div class="pieIzq">
        <p>Copyright © 2022 All Rights Reserved by Adhok.</p>
      </div>
      <div class="pieDer">
        <div class="redesPie fb" v-on:click="fbbutton"></div>
        <div class="redesPie in" v-on:click="inButton"></div>
        <div class="redesPie tw"></div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  data: function () {
    return {
      msjAlert: false
    };
  },
  methods: {

    btnMensaje: function()
    {
      var thisTemporal = this;
      //var urlAPI = 'http://localhost:4000';
      var urlAPI = 'https://adkapi.tech';

      var nombre = document.getElementById('nombre').value;
      var email = document.getElementById('email').value;
      
      var desarrollo = document.getElementById('desarrollo');
      var opc = desarrollo.value;

      /*console.log(nombre);
      console.log(opc);*/

      if (nombre == "" || nombre == null)
      {
        alert("Completa la información");
      }
      else
      {
        if(email == "" || email == null)
        {
          alert("Completa la información");
        }
        else
        {
    
          let response = {"nombre": nombre, "email": email, "desarrollo": opc};

           fetch(urlAPI + '/emailAdhok', {body: JSON.stringify(response), method: 'post', headers: {'Content-Type': 'application/json' /*'Authorization': localStorage.getItem('JWTTOKEN')*/}})
            .then(function(respuesta)
            {
              /*console.log('Acceso al endpoint');
              console.log(respuesta);*/

              if(respuesta.status == 200)
              {
                //alert("Mensaje enviado correctamente");
                //thisTemporal.msjAlert = true;

                fetch(urlAPI + '/emailAdhokRespuesta', {body: JSON.stringify(response), method: 'post', headers: {'Content-Type': 'application/json' /*'Authorization': localStorage.getItem('JWTTOKEN')*/}})
                .then(function(respuesta)
                {
                  /*console.log('Acceso al endpoint');
                  console.log(respuesta);*/

                  if(respuesta.status == 200)
                  {
                    //alert("Mensaje enviado correctamente");
                    thisTemporal.msjAlert = true;
                  }
                  else
                  {
                    alert("Hubo un error, intentalo más tarde");
                  }
                })
                .catch(err => {
                  console.log(err);
                })


              }
              else
              {
                alert("Hubo un error, intentalo más tarde");
              }
            })
            .catch(err => {
              console.log(err);
            })
           

        }
      }


    },
    btnEntendido: function()
    {
      var thisTemporal = this;
      thisTemporal.msjAlert = false;
    },
    scrollHome: function () {
      var home = document.getElementById("home");
      home.scrollIntoView({ behavior: "smooth", block: "center" });
    },
    scrollServicios: function () {
      var servicios = document.getElementById("servicios");
      servicios.scrollIntoView({ behavior: "smooth", block: "center" });
    },
    scrollContacto: function () {
      var contacto = document.getElementById("contacto");
      contacto.scrollIntoView({ behavior: "smooth", block: "center" });

    },
    handleScroll: function () {
      
      var contacto = document.getElementById("contacto");
      var topContacto = contacto.scrollTop;
      

      if (window.pageYOffset >= window.innerHeight / 2) {
        //console.log(window.innerHeight);

        document.getElementById("inicioOp").classList.add("scrollcont");
        document.getElementById("serviciosOp").classList.add("scrollcont");
        document.getElementById("contactoOp").classList.add("scrollcont");
        document.getElementById("linea").classList.add("scrollcont");
        document.getElementById("lineaRedes").classList.add("scrollcont");
        document.getElementById("izq").classList.add("lineaVerde");
        document.getElementById("fb").classList.add("scrollcont");
        document.getElementById("ins").classList.add("scrollcont");

        /*KEYFRAMES*/
        document.getElementById("serviciosIzq").classList.add("opacidadInCss");

        setTimeout(function ()
        {
          document.getElementById("devWeb").classList.add("moveItemCss");
          document.getElementById("seo").classList.add("moveItemCss");
        }, 50);

        setTimeout(function ()
        {
          document.getElementById("uxUi").classList.add("moveItemCss");
          document.getElementById("apis").classList.add("moveItemCss");
        }, 800);
      }
      else if (window.pageYOffset < window.innerHeight / 2)
      {
        document.getElementById("inicioOp").classList.remove("scrollcont");
        document.getElementById("serviciosOp").classList.remove("scrollcont");
        document.getElementById("contactoOp").classList.remove("scrollcont");
        document.getElementById("linea").classList.remove("scrollcont");
        document.getElementById("lineaRedes").classList.remove("scrollcont");
        document.getElementById("izq").classList.remove("lineaVerde");
        document.getElementById("fb").classList.remove("scrollcont");
        document.getElementById("ins").classList.remove("scrollcont");

        /*KEYFRAMES*/
        document.getElementById("serviciosIzq").classList.remove("opacidadInCss");

        document.getElementById('devWeb').classList.remove('moveItemCss');
        document.getElementById('seo').classList.remove('moveItemCss');
        document.getElementById('uxUi').classList.remove('moveItemCss');
        document.getElementById('apis').classList.remove('moveItemCss');

        document.getElementById("contactoIzq").classList.remove("opacidadInCss");

        
      }
      if (window.pageYOffset >= (document.getElementById('home').offsetHeight + document.getElementById('servicios').offsetHeight) )
      {
        //console.log("tercer seccion");
        document.getElementById("contactoIzq").classList.add("opacidadInCss");
  
      }
    },

    fbbutton: function()
    {
      window.open("https://www.facebook.com/adhokMX", "_blank");
    }, 

    twButton: function() 
    {
      //window.open("https://www.facebook.com/adhokMX", "_blank");
    }, 

    inButton: function()
    {
      window.open("https://www.instagram.com/adhok_mx/", "_blank");
    }
  },
  computed: {},
  mounted: function () {
    this.scrollHome();
    //this.$ga.enable();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*KEYFRAMES*/
@keyframes barraInicio {
  from {
    height: 40%;
  }
  to {
    height: 92%;
  }
}

@keyframes opacidadIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes moveItem {
  from {
    opacity: 0;
    width: 50%;
  }
  to {
    opacity: 1;
    width: 70%;
  }
}

.opacidadInCss {
  animation: opacidadIn 2s linear;
  opacity: 1 !important;
}

.main {
  /*height: 100vh;*/
  overflow: auto;
  scroll-snap-type: y mandatory;

  background-image: url("../assets/rectangulo.png"),
    url("../assets/rectangulo.png");
  background-repeat: no-repeat, no-repeat;
  background-position: top right, bottom left;
  background-attachment: fixed;
}

.seccion1 {
  width: 100%;
  height: 100vh;
  background: url("../assets/inicio3.jpg") no-repeat center center;
  background-size: 100% auto;

  
}

.izq {
  position: fixed;
  left: 0;
  width: 12.2%;
  height: 92%;
  border-right: 3px solid #fff;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  animation: barraInicio 1s linear;
  transition: all 0.5s ease-in-out;
}

.izq img {
  width: 60px;
  height: 50px;
  margin-bottom: 20px;
  cursor: pointer;
}

.contRedes {
  width: calc(100% - 50px);
  height: 92%;
  transform: rotate(270deg);

  display: flex;
  justify-content: center;
  align-items: center;
  animation: opacidadIn 2s linear;
}

.contRedes p {
  color: #fff;
  font-size: 13px;
  margin: 0;
  font-weight: 550;
  letter-spacing: 1px;
  font-family: monospace;
  display: flex;
}

.contRedes p span
{
    padding: 0 10px;
    box-sizing: border-box;
}

.contRedes p a {
  text-decoration: none;
  color: #fff;

}

.der {
  width: 87%;
  height: 92%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contSeccion {
  position: fixed;
  top: 4%;
  animation: opacidadIn 2s linear;
}

.contSeccion p {
  color: #fff;
  font-size: 13px;
  margin: 0;
  font-family: monospace;
}

.contSeccion p a {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  font-weight: 550;
  letter-spacing: 1px;
}

.der h2 {
  color: #fff;
  font-size: 35px;
  font-weight: normal;
  font-family: monospace;
  animation: opacidadIn 2s linear;
}

.der h2 p {
  margin: 0;
  color: #bae2ae;
  font-weight: bolder;
  display: inline-block;
  font-size: 40px;
  font-family: monospace;
}

.der button {
  width: 20%;
  height: 5%;
  color: #3b3b3b;
  background-color: #bae2ae;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: monospace;
  font-weight: bolder;
  letter-spacing: 1px;
  animation: opacidadIn 1s linear;
}

.der button:hover
{
  box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.10);
}

/*SCROLL*/
.scrollcont {
  color: #bae2ae !important;
  transition: all 0.1s linear;
}

.lineaVerde {
  border-right: 3px solid #bae2ae;
  transition: all 0.5s linear;
}

.seccion2 {
  width: 100%;
  height: 100vh;
  background-color: #fff;

  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.derSeccion2 {
  width: 87%;
  height: 92%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.serviciosIzq {
  width: 30%;
  height: 100%;

  padding-left: 30px;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  opacity: 0;
  transition: all 1s linear;
}

.serviciosIzq h2 {
  color: #bae2ae;
  font-size: 35px;
  font-family: monospace;
}

.serviciosIzq p {
  text-align: left;
  margin: 0;
  font-family: monospace;
  font-size: 15px;
}

.serviciosDer {
  width: 70%;
  height: 90%;

  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

#devWeb,
#uxUi,
#seo,
#apis {
  opacity: 0;
}

.item {
    width: 50%;
  height: 15%;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s linear;

}
.moveItemCss {
  animation: moveItem 1s linear;
  width: 70%;
  opacity: 1 !important;
}

.imagen {
  width: 13%;
  height: 90%;
  background-color: #bae2ae;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.imagen img {
  width: 40px;
  height: 40px;
}

.texto {
  width: 85%;
  height: 100%;

  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.textoIzquierda {
  text-align: left;
}

.textoDerecha {
  text-align: right;
}

.textoIzquierda h2 {
  color: #5b6366;
  font-size: 20px;
  margin: 0;
  margin-left: 12px;
  font-weight: normal;
  font-family: monospace;
}

.textoIzquierda p {
  color: #5b6366;
  font-size: 12px;
  margin: 0;
  margin-left: 12px;
  font-family: monospace;
}

.textoDerecha h2 {
  color: #5b6366;
  font-size: 20px;
  margin: 0;
  margin-right: 12px;
  font-weight: normal;
  font-family: monospace;
}

.textoDerecha p {
  color: #5b6366;
  font-size: 12px;
  margin: 0;
  margin-right: 12px;
  font-family: monospace;
}

.seccion3 {
  width: 100%;
  height: 100vh;
  background-color: #fff;

  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.derSeccion3 {
  width: 87%;
  height: 92%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactoIzq {
  width: 30%;
  height: 100%;

  padding-left: 30px;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  opacity: 0;
  transition: all 1s linear;
}

.contactoIzq h2 {
  color: #bae2ae;
  font-size: 35px;
  font-family: monospace;
  margin: 0;
}

.contactoIzq p {
  text-align: left;
  margin: 0;
  font-family: monospace;
  font-size: 15px;
}

.contactoDer {
  width: 80%;
  height: 90%;

  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.contactoDer form {
  width: 40%;
  height: 60%;
}

.rowForm {
  width: 100%;
  height: 25%;

  display: flex;
  justify-content: space-around;
  align-items: center;
}

.rowForm p {
  color: #5b6366;
  font-family: monospace;
  font-size: 15px;
  margin: 0;
}

.rowForm input {
  width: 65%;
  border: none;
  border-bottom: 1px solid #707070;
  font-family: monospace;
}

.rowForm select {
  width: 70%;
  height: 30px;
  border: none;
  border-bottom: 1px solid #707070;
  cursor: pointer;
  font-family: monospace;
  box-sizing: border-box;
}

.derecha {
  justify-content: flex-end;
}

.rowForm button {
  width: 70%;
  height: 40%;
  background-color: #bae2ae;
  color: #3b3b3b;;
  border: none;
  border-radius: 4px;
  letter-spacing: 1px;
  font-family: monospace;
  font-weight: bolder;
  cursor: pointer;
}

.rowForm button
{
  box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.10);
}

.pie {
  width: 100%;
  height: 15vh;
  background-color: #bae2ae;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
}

.pieIzq, .pieDer
{
  width: 50%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pieIzq p 
{
  font-family: monospace;
}

.redesPie
{
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.tw 
{
  background: url("../assets/gorjeo.png") no-repeat center center;
  background-size: 60% auto;
  background-color: #fff;
}

.fb 
{
  background: url("../assets/facebook.png") no-repeat center center;
  background-size: 60% auto;
  background-color: #fff;
}

.in 
{
  background: url("../assets/instagram.png") no-repeat center center;
  background-size: 60% auto;
  background-color: #fff;
}

.msjForm
{
  position: fixed;
  width: 35%;
  height: 50%;
  left: 32.5%;
  top: 25%;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  z-index: 10;
  padding: 10px 20px;

  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.msjForm h2
{
  color: #bae2ae;
  font-family: monospace;
  font-size: 40px;
  margin: 0;
}

.msjForm p 
{
  color: #5B6366;
  text-align: center;
  margin: 0;
  font-family: monospace;
  font-size: 18px;
  
}

.msjForm button
{
  width: 50%;
  height: 10%;
  background-color: #bae2ae;
  color: #3b3b3b;
  border: none;
  border-radius: 4px;
  font-weight: bolder;
  letter-spacing: 1px;
  cursor: pointer;
}

.msjForm button:hover
{
  box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.10);
}

/*@media (min-width:851px) and (max-width:1914px)
{

}*/

@media (min-width: 561px) and (max-width: 850px) and (orientation: portrait)
{
  .seccion1
  {
    background-size: auto 100%;
    justify-content: flex-end;
    
  }

  .der 
  {
    margin-left: 15%;
  }

  .izq 
  {
    width: 15%;
  }

  .izq img 
  {
    width: 30px;
    height: 30px;
    margin-top: 20px;
  }

  .der button
  {
    width: 70%;
  }
  
  .der h2 
  {
    padding: 0 2px;
  }

  .seccion2 
  {
    align-items: flex-start;
  }

  .derSeccion2
  {
    height: 99%;
    flex-direction: column;
  }

  .imagen
  {
    width: 20%;
    height: 70%;
  }

  .texto
  {
    width: 80%;
    justify-content: center;
  }

  .serviciosIzq
  {
    width: 90%;
    height: 30%;
  }

  .serviciosIzq h2 
  {
    font-size: 20px;
    
  }

  .serviciosIzq p
  {
    font-size: 13px;
  }

  .serviciosDer
  {
    width: 100%;
    height: 70%;
  }

  .item
  {
    height: 20%;
  }

  .textoIzquierda h2 
  {
    font-size: 12px;
    margin-bottom: 3px;
    font-weight: bolder;
  }

  .textoIzquierda p 
  {
    font-size: 11px;
  }

  .textoDerecha h2 
  {
    font-size: 12px;
    margin-bottom: 3px;
    font-weight: bolder;
  }

  .textoDerecha p 
  {
    font-size: 10px;
  }

  .seccion3 
  {
    align-items: flex-start;
  }

  .derSeccion3
  {
    flex-direction: column-reverse;
  }

  .contactoIzq
  {
    width: 90%;
    height: 10%;
    align-items: center;
  }

  .contactoIzq h2 
  {
    font-size: 20px;
  }

  .contactoIzq p 
  {
    text-align: center;
    font-size: 13px;
  }

  .contactoDer
  {
    width: 90%;
    height: 70%;
  }

  .contactoDer form
  {
    width: 95%;
    height: 80%;
  }

  .rowForm p 
  {
    font-size: 13px;
  }

  .rowForm select 
  {
    font-size: 13px;
  }

  .derecha
  {
    justify-content: center;
  }

  .pieIzq p 
  {
    font-size: 10px;
  }

  .msjForm h2 
  {
    font-size: 30px;
  }

  .msjForm p 
  {
    font-size: 13px;
  }
}

@media (max-width:575px)
{
  .seccion1
  {
    background-size: auto 100%;
    justify-content: flex-end;
    
  }

  .der 
  {
    margin-left: 15%;
  }

  .izq 
  {
    width: 15%;
  }

  .izq img 
  {
    width: 30px;
    height: 30px;
    margin-top: 20px;
  }

  .der button
  {
    width: 70%;
  }

  .der h2 
  {
    padding: 0 2px;
  }

  .seccion2 
  {
    align-items: flex-start;
  }

  .derSeccion2
  {
    height: 99%;
    flex-direction: column;
  }

  .imagen
  {
    width: 20%;
    height: 70%;
  }

  .texto
  {
    width: 80%;
    justify-content: center;
  }

  .serviciosIzq
  {
    width: 90%;
    height: 30%;
  }

  .serviciosIzq h2 
  {
    font-size: 20px;
    
  }

  .serviciosIzq p
  {
    font-size: 13px;
  }

  .serviciosDer
  {
    width: 100%;
    height: 70%;
  }

  .item
  {
    height: 20%;
  }

  .textoIzquierda h2 
  {
    font-size: 12px;
    margin-bottom: 3px;
    font-weight: bolder;
  }

  .textoIzquierda p 
  {
    font-size: 11px;
  }

  .textoDerecha h2 
  {
    font-size: 12px;
    margin-bottom: 3px;
    font-weight: bolder;
  }

  .textoDerecha p 
  {
    font-size: 10px;
  }

  .seccion3 
  {
    align-items: flex-start;
  }

  .derSeccion3
  {
    flex-direction: column-reverse;
  }

  .contactoIzq
  {
    width: 90%;
    height: 10%;
    align-items: center;
  }

  .contactoIzq h2 
  {
    font-size: 20px;
  }

  .contactoIzq p 
  {
    text-align: center;
    font-size: 13px;
  }

  .contactoDer
  {
    width: 90%;
    height: 70%;
  }

  .contactoDer form
  {
    width: 95%;
    height: 80%;
  }

  .rowForm p 
  {
    font-size: 13px;
  }

  .rowForm select 
  {
    font-size: 13px;
  }

  .derecha
  {
    justify-content: center;
  }

  .pieIzq p 
  {
    font-size: 10px;
  }

  .msjForm
  {
    width: 60%;
    left: 20%;
  }

  .msjForm h2 
  {
    font-size: 30px;
  }

  .msjForm p 
  {
    font-size: 13px;
  }

}
</style>
